<div
  class="category-menu"
  fxLayout="row nowrap"
  fxLayoutAlign="space-between center"
>
  <button
    mat-button
    class="category-btn"
    (click)="showDropDown = true; selectCategory(this.selectedCategory)"
    [ngClass]="{ 'category-btn__clicked': showDropDown }"
  >
    <div class="cat-align">
      <span>Catégories</span>
      <img
        src="{{
          showDropDown
            ? 'assets/icons/arrow_drop_down-white.svg'
            : 'assets/icons/arrow_drop_down-blue.svg'
        }}"
        alt=""
      />
    </div>
  </button>

  <div *ngIf="false" class="category">
    <a routerLink="/blog/list" routerLinkActive="active-link">Actualités</a>
  </div>
  <div class="category">
    <a routerLink="about/foire-aux-questions" routerLinkActive="active-link"
      >Foire aux questions</a
    >
  </div>
  <div class="category">
    <a routerLink="/about/about-us" routerLinkActive="active-link"
      >Qui sommes-nous?</a
    >
  </div>
</div>

<div
  (window:mouseup)="autoCloseForDropdownCars($event)"
  (mouseleave)="showDropDown = false"
  class="drop-down"
  [ngClass]="{ 'show-drop-down': showDropDown }"
  (click)="selectedCategory = null"
>
  <nav
    [ngClass]="{ 'no-scroll': parentCategories.length <= 10 }"
    class="drop-down__main-block"
  >
    <ul>
      <li *ngFor="let cat of parentCategories; let index = i">
        <button
          mat-button
          class="cat-list"
          [ngClass]="{
            'selected-cat': selectedCategory?.id === cat.id && showDropDown
          }"
          (click)="
            !tablet &&
              navigate(cat.id, cat.name, cat.status, null, '', null, {
                inStock: true
              });
            showDropDown = false
          "
          (mouseenter)="selectCategory(cat)"
        >
          {{ cat.name.charAt(0).toUpperCase() + cat.name.slice(1) }}
        </button>
      </li>
    </ul>
  </nav>

  <div class="drop-down__sub-block" fxLayout="row wrap">
    <div
      *ngFor="let subCat of subCategories"
      fxLayout="column"
      style="gap: 6px"
      fxFlex="{{ subCategories.length > 3 ? 25 : 100 / subCategories.length }}"
    >
      <a
        class="sub-category-2"
        (click)="
          showDropDown = false;
          navigate(
            selectedCategory.id,
            selectedCategory.name,
            selectedCategory.status,
            subCat.id,
            subCat.name,
            subCat.status
          )
        "
      >
        {{ subCat.name.charAt(0).toUpperCase() + subCat.name.slice(1) }}
      </a>
      <a
        class="children-cat"
        *ngFor="let subSubCat of subCat.children.slice(0, showItems)"
        (click)="
          showDropDown = false;
          navigate(
            selectedCategory.id,
            selectedCategory.name,
            selectedCategory.status,
            subCat.id,
            subCat.name,
            subCat.status,
            {
              categories: subSubCat.id
            }
          )
        "
      >
        {{ subSubCat.name.charAt(0).toUpperCase() + subSubCat.name.slice(1) }}
      </a>
      <a
        class="see-more"
        *ngIf="subCat.children.length > 7"
        (click)="toggleShowItems(subCat.children)"
      >
        Voir {{ showItems === 7 ? "Plus" : "Moins" }}
      </a>
    </div>
  </div>
</div>
