import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DaysLeftPipe} from 'shared/pipes/days-left/days-left.pipe';
import {ProductCardDTO} from '../../../../../projects/tekkeys-common/src/lib/DTO/product-card.dto';
import {ProductDTO} from 'tekkeys-common';
import {ActivatedRoute, Router} from '@angular/router';
import {selectAccount, selectIsConnected} from 'store/account/account.selectors';
import {Observable, Subject, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {AccountState} from 'store/account/account.state';
import {CompanyState} from 'store/company/company.state';
import {NotificationsState} from 'store/notifications/notifications.state';
import {addToQuotationItem} from 'store/quotation/quotation.actions';
import {LocalQuotationService} from 'shared/services/localQuotations.service';
import {CartItemDTO} from '../../../../../projects/tekkeys-common/src/lib/DTO/cart-item.dto';
import {selectAddCartStatus, selectCart} from 'store/cart/cart.selectors';
import {UntypedFormControl} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {addToCart} from 'src/app/store/cart/cart.actions';
import {AddToCardPopupComponent} from '../../../catalog/components/add-to-card-popup/add-to-card-popup.component';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {selectCompanyId} from 'store/company/company.selectors';
import {SnackbarDialogComponent} from 'shared/components/product-card/snackbar-dialog/snackbar-dialog.component';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() product: ProductCardDTO;
  @Input() productDialog: ProductDTO;
  @Input() cardType = null;
  @Input() desactivateRouter = false;
  quantityLeft = 0;
  overQuantity: boolean;
  devisQuanitity = 0;
  @Output() productSent: EventEmitter<ProductDTO> = new EventEmitter<null>();
  quantityInCart = 0;
  image: string = null;
  isConnected: boolean;
  discountDaysLeft: number = null;
  subscriptions: Subscription[] = [];
  cartItems: Observable<CartItemDTO[]> = this.store.select(selectCart);
  quantity: UntypedFormControl = new UntypedFormControl(1);
  max: number;
  addToCartLoading = new Subject<boolean>();
  clientRole: string;
  companyId: number;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private localQuotationService: LocalQuotationService,
    private store: Store<{
      account: AccountState;
      company: CompanyState;
      notifications: NotificationsState;

    }>,
  ) {
  }

  ngOnInit() {
    this.image = this.product.link;
    const daysLeftPipe = new DaysLeftPipe();
    if (this.product.discount) {
      this.discountDaysLeft = daysLeftPipe.transform(
        new Date(this.product?.discountEndDate)
      );
    }
    this.subscriptions.push(this.store.select(selectIsConnected).subscribe((isConnected) => {
      this.isConnected = isConnected;

    }));
    this.route.data.subscribe((data) => {
      this.store
        .select(selectIsConnected)
        .subscribe((e) => (this.isConnected = e));
      this.max = data?.product?.quantity;
      if (data.product?.status !== 'IN_STOCK') {
        this.max = Infinity;
      }
      this.cartItems.forEach((c) => {
        this.quantityInCart = 0;
        c.forEach((cart) => {
          if (cart.product.id === this.product.id) {
            this.quantityInCart = cart?.quantity;
          }
        });
      });
    });
    this.store.select(selectAccount).subscribe(me => {
      if (me) {
        this.clientRole = me.role;
      }
    });


    this.store.select(selectCompanyId).subscribe(companyId => {
      this.companyId = companyId;
    });
  }
  addToCart() {
    const total = this.quantity.value + this.quantityInCart;
    if (total > this.product.quantity) {
      this.handleQuantityExceeded(total);
    } else {
      this.overQuantity = false;
    }

    if (this.isConnected) {
      this.handleConnectedUser();
    } else {
      this.router.navigate(['/account/login'], {
        queryParams: { returnUrl: this.router.url },
      });
    }
  }

  private handleQuantityExceeded(total: number) {
    this.overQuantity = true;
    if (this.quantity.value >= this.product?.quantity) {
      this.devisQuanitity = total - this.product?.quantity;
      this.quantity.setValue(this.product.quantity - this.quantityInCart);
    } else if (this.quantity.value < this.product.quantity) {
      this.quantityLeft = this.product.quantity - this.quantityInCart;
      this.devisQuanitity = total - this.product.quantity;
      this.quantity.setValue(this.quantityLeft);
    }
  }

  private handleConnectedUser() {
    if (this.clientRole === 'ROLE_VIESTURS') {
      this.openSnackBar();
      
    } else if (this.companyId == null){
      this.creatCompany();
    } else {
      this.dispatchAddToCart();
    }
  }

  private dispatchAddToCart() {
    this.store.dispatch(
      addToCart({ productId: this.product.id, quantity: this.quantity.value })
    );

    const selectAddCartStateSub = this.store
      .select(selectAddCartStatus)
      .subscribe((state) => {
        this.addToCartLoading.next(state.isLoading);
        if (!state.isLoading && !state.error && state.lastCartItemId) {
          this.showDialogBox(state.lastCartItemId);
          setTimeout(() => selectAddCartStateSub.unsubscribe(), 500);
        }
        if (!state.isLoading && state.error) {
          this.showDialogBox(state.lastCartItemId);
          setTimeout(() => selectAddCartStateSub.unsubscribe(), 500);
        }
      });
    this.subscriptions.push(selectAddCartStateSub);
  }
  modifyProductName(name: string): string {
    if (!name) {
      return '';
    }
    return name.replace(/\//g, '-').replace(/[()]/g, '-');
  }

  openSnackBar() {
    this.snackBar.dismiss(); // Dismiss any existing snackbar

    this.snackBar.open('En tant que visiteur, vous ne pouvez pas effectuer d\'achats. Veuillez contacter l\'Administrateur de votre entreprise', 'FERMER', {
      duration: 3000,
      panelClass: ['mat-toolbar', 'mat-warn']
    });
  }

  creatCompany() {
    this.router.navigate(['/profile/company']);
    this.dialog.open(SnackbarDialogComponent);

  }

  showDialogBox(cartItemId: number) {
    this.dialog.closeAll()
    const data = {
      product: this.product,
      quantity: this.quantity.value,
      cartQuantity: this.quantityInCart,
      overQuantity: this.overQuantity,
      devisQuantity: this.devisQuanitity,
      localCartQuantity: this.quantityInCart,
      status: this.product.productStatus,
      image: this.image,
      cartItemId,
    };

    this.dialog.open(AddToCardPopupComponent, {
      data,
      maxWidth: '100%',
    });
  }

  sendProduct() {
    this.productSent.emit(this.productDialog);
  }

  addQuote() {
    if (!this.isConnected) {

      this.localQuotationService.addQuotationItem(
        this.productDialog,
        1,
      );
      this.showDialogBox(0);
    } else if (this.clientRole === 'ROLE_VIESTURS') {
        return this.openSnackBar();
      } else if (this.companyId == null){
          this.creatCompany();
        } else {
          this.store.dispatch(
            addToQuotationItem({
              productId: this.product.id,
              quantity: 1,
              price: this.product.price,
            })
          );
          this.showDialogBox(0);
        }
  }

  navigate() {
    if (!this.desactivateRouter) {
      const modifiedProductName = this.modifyProductName(this.product.name);
      this.router.navigateByUrl('/catalog/product/' + this.product.id + '/' + modifiedProductName);
    }
  }
}
