import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {LocalCartService} from "shared/services/localCart.service";
import {LocalQuotationService} from "shared/services/localQuotations.service";
import {Observable} from "rxjs";
import {CartItemDTO, CompanyDTO, ProductDTO} from "tekkeys-common";
import {selectCart} from "store/cart/cart.selectors";
import {selectIsConnected} from "store/account/account.selectors";
import {addToQuotationItem} from "store/quotation/quotation.actions";
import {UntypedFormControl} from "@angular/forms";
import { AccountState } from 'store/account/account.state';

export interface DATAPRODUCT {
  cartQuantity: number;
  product: ProductDTO;
  quantity: number;
  cartItemId: number;
  overQuantity: number;
  devisQuantity: number;
  localCartQuantity: number;
  remainder: boolean;
  status: string;
}
@Component({
  selector: 'app-add-to-devis-popup',
  templateUrl: './add-to-devis-popup.component.html',
  styleUrls: ['./add-to-devis-popup.component.scss']
})
export class AddToDevisPopupComponent implements OnInit {
  MESSAGE =
    'Livraison estimée dans 24-48 heures après la validation de la commande.';
  imageUrl = null;
  finalQuantity = 0;
  quantity: UntypedFormControl = new UntypedFormControl();
  isConnected: boolean;
  cartStore: Observable<CartItemDTO[]> = this.store.select(selectCart);
  hasCompany: boolean;
  company: CompanyDTO;
      
  constructor(
    private companyStore: Store<{ company; CompanyState ; account: AccountState }>,
    private dialogRef: MatDialogRef<AddToDevisPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DATAPRODUCT,
    private router: Router,
    private store: Store,
    private localCartService: LocalCartService,
    private localQuotationService: LocalQuotationService

  ) {
    this.store
        .select(selectIsConnected)
      .subscribe((e) => {
        this.isConnected = e;
      });
    
    this.companyStore.select('account').subscribe(value => {
      if (value.account) {
        if (value.account.companyId === 0) {
          this.hasCompany = false;
        }
        else { this.hasCompany = !(value.account.companyId !== 0 && this.company?.activationStatus === 'waiting'); } }
    });

  }

  ngOnInit(): void {
    this.quantity.setValue(this.data.quantity);
    this.store.select(selectIsConnected).subscribe((isConnected) => {

      if (isConnected) {
        this.finalQuantity = this.data?.cartQuantity + this.data.quantity - this.quantity.value ;
      }
      else if (!isConnected){
        this.finalQuantity = this.data.cartQuantity + this.data.quantity - this.quantity.value ;
      }
    });
    this.imageUrl = this.data.product?.productResources[0]?.link;
  }
  OpenQuotation(){
    this.store.select(selectIsConnected).subscribe((isConnected) => {
      if (!isConnected) {
        this.localQuotationService.addQuotationItem(
          this.data.product,
          this.data.devisQuantity
        );
        return;
      }
      const prices = (this.data.product.price - this.data.product.discount) * this.finalQuantity;
      this.store.dispatch(
        addToQuotationItem({
          productId: this.data.product.id,
          quantity: this.data.devisQuantity,
          price: prices,
        })
      );
    });
    this.router.navigate(['/checkout']);

  }

  goToCart() {
    this.close();
    this.router.navigate(['/checkout']);
  }
  close() {
    this.dialogRef.close();
  }
}
