import { Component, Inject, OnInit } from '@angular/core';
import {ProductDTO, CartItemDTO, QuotationItemDto, CompanyDTO} from 'tekkeys-common';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { setQuantity } from 'store/cart/cart.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCart, selectCartItem } from 'store/cart/cart.selectors';
import { selectIsConnected } from 'store/account/account.selectors';
import { LocalCartService } from 'shared/services/localCart.service';
import {selectQuotation} from 'store/quotation/quotation.selectors';
import {addToQuotationItem} from 'store/quotation/quotation.actions';
import {LocalQuotationService} from 'shared/services/localQuotations.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { AccountState } from 'store/account/account.state';

export interface DATAPRODUCT {
  cartQuantity: number;
  product: ProductDTO;
  quantity: number;
  cartItemId: number;
  overQuantity: number;
  devisQuantity: number;
  localCartQuantity: number;
  remainder: boolean;
  status: string;
}

@Component({
  selector: 'app-add-to-cart-dialog',
  templateUrl: './add-to-cart-dialog.component.html',
  styleUrls: ['./add-to-cart-dialog.component.scss'],
})
export class AddToCartDialogComponent implements OnInit {
  MESSAGE =
    'Livraison estimée dans 24-48 heures après la validation de la commande.';
  imageUrl = null;
  cartStore: Observable<CartItemDTO[]> = this.store.select(selectCart);
  quotationStore: Observable<QuotationItemDto[]> = this.store.select(
    selectQuotation
  );
  cartItem = this.store.select(selectCartItem(this.data.cartItemId));
  quantity: UntypedFormControl = new UntypedFormControl();
  max: number;
  datas: any;
  quotation: boolean;
  finalQuantity = 0;
  public appForm = new UntypedFormGroup({
    name: new UntypedFormControl(),
  });
  isConnected: boolean;
  hasCompany: boolean;
  company: CompanyDTO;
      
  constructor(
    private companyStore: Store<{ company; CompanyState ; account: AccountState }>,
    private dialogRef: MatDialogRef<AddToCartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DATAPRODUCT,
    private router: Router,
    private store: Store,
    private localCartService: LocalCartService,
    private localQuotationService: LocalQuotationService

  ) {
    
    this.companyStore.select('account').subscribe(value => {
      if (value.account) {
        if (value.account.companyId === 0) {
          this.hasCompany = false;
        }
        else { this.hasCompany = !(value.account.companyId !== 0 && this.company?.activationStatus === 'waiting'); } }
    });

  }

  ngOnInit(): void {
    this.finalQuantity = 0;
    this.quotation =  !this.data.remainder;
    this.quantity.setValue(this.data.quantity);
    this.store.select(selectIsConnected).subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (isConnected) {
        this.finalQuantity = this.data?.cartQuantity + this.data.quantity - this.quantity.value ;
      }
      else if (!isConnected){
        this.finalQuantity = this.data.cartQuantity + this.data.quantity - this.quantity.value ;
      }
    });
    this.imageUrl = this.data.product?.productResources[0]?.link;
    if (!this.imageUrl) {
      this.imageUrl = 'assets/imtech-new-logo.svg';
    }
    let quantity = this.data.quantity;
    this.max = this.data.product.quantity;
    this.quantity.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((payload: number) => {
        this.store.select(selectIsConnected).subscribe((isConnected) => {
          if (!isConnected) {
            const cartItem = this.localCartService.getCartItem(
              this.data.product.id
            );
            this.localCartService.addCartItem(
              cartItem.product,
              payload - quantity
            );
            quantity = payload;
          }
        });
        this.cartItem.pipe(take(1)).subscribe((carTItem) => {
          this.store.dispatch(
            setQuantity({
              cartItemId: this.data.cartItemId,
              quantity: carTItem.quantity + payload - quantity,
            })
          );
        });

        quantity = payload;
      });
  }

  close() {
    this.dialogRef.close();
  }

  goToCart() {
    this.close();
    this.router.navigate(['/checkout']);
  }
  OpenQuotation(){
    this.store.select(selectIsConnected).subscribe((isConnected) => {
      if (!isConnected) {
        this.localQuotationService.addQuotationItem(
          this.data.product,
          this.data.devisQuantity
        );
        return;
      }
      const prices = (this.data.product.price - this.data.product.discount) * this.finalQuantity;
      this.store.dispatch(
        addToQuotationItem({
          productId: this.data.product.id,
          quantity: this.data.devisQuantity,
          price: prices,
        })
      );
    });
    this.router.navigate(['/checkout']);

  }

  /*OpenQuotation() {
    this.store.select(selectIsConnected).subscribe((isConnected) => {
      if (!isConnected) {
        this.router.navigate(['/account/login'], {
          queryParams: { returnUrl: '/checkout' },
        });
        return;
      }
      this.quotationStore.subscribe((quotationItems) => {
        this.datas = {
          products: quotationItems,
          quantity: this.data.devisQuantity,
          overQuantity: this.data.overQuantity,
          designation: this.data.product.designation
        };
      });
      const data = this.datas;
      this.dialog.open(NewQuotationDialogComponent, {
        data,
        maxWidth: '100%',
      });
    });

  }*/
}
