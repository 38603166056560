import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CartItemDTO } from 'tekkeys-common';

import { cartAdapter, CartState } from './cart.state';

const adapterSelectors = cartAdapter.getSelectors();

const getCartState = createFeatureSelector<CartState>('cart');

export const selectCart = createSelector(
  getCartState,
  adapterSelectors.selectAll
);

export const selectCartItem = (cartId: number) =>
  createSelector(selectCart, (cartItems) =>
    cartItems.find((cartItem) => cartItem.id === cartId)
  );

export const selectCartItemsCount = createSelector(
  getCartState,
  adapterSelectors.selectTotal
);

export const selectItemsCount = createSelector(
  getCartState,
  adapterSelectors.selectTotal
);

export const selectAddCartStatus = createSelector(getCartState, (state) => ({
  isLoading: state.isLoading,
  error: state.error,
  lastCartItemId: state.lastCartItemId,
}));

export const selectTotalCartItemsCount = createSelector(
  selectCart,
  (state: CartItemDTO[]) =>
    state.reduce(
      (acc: number, cur: CartItemDTO) => {
        return acc +
          Number((cur.product.price - (cur.product.discount ? (cur.product.discount * cur.product.price) : 0)).toFixed(2)) *
          cur.quantity;
      },
      0
    )
);


export const selectShippingCost = createSelector(
  selectCart,
  (state: CartItemDTO[]) =>
    state.filter((cart) => cart.product.physical).length ? 15 : 0
);
export const selectError = createSelector(
  getCartState,
  (state) => state.error
);
export const getErrorMessage = createSelector(
  getCartState,
  (state) => state.errorMessages
);
