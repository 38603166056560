import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { OrderDto } from '../../DTO/order.dto';
import { OrderOptDto } from '../../DTO/order-opt.dto';
import { PageableDTO } from '../../DTO/pageable.dto';
import {OrderItemDto} from '../../DTO/orderItem.dto';
import {OrderValidDTO} from '../../DTO/order-valid.dto';
import { LicensKey } from '../../DTO/licensKey.dto';
import { StatusSeenDto } from '../../DTO/statusSeen.dto';


@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/order`;
  }

  public checkout(
    deliveryType: string,
    paymentType: string,
   // billingAddress: string,
    deliveryCode: string,
    promoCode?: string,
  ): Observable<OrderValidDTO> {
    return  this.http.post<OrderValidDTO>(this.url, {
      deliveryType,
      paymentType,
   //   billingAddress,
       deliveryCode,
      promoCode ,
    });
  }
  public payWithOutstanding(orderId: number): Observable<OrderDto> {
    const url = `${this.url}/pay/outstanding/${orderId}`;
    return this.http.post<OrderDto>(url, null);
  }

  public getMyOrders(page: number, size: number) {
    const url = `${this.url}`;
    const params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());
    return this.http.get<PageableDTO<OrderOptDto>>(url, { params });
  }

  public getOrderById(id: number): Observable<OrderDto> {
    const url = `${this.url}/${id}`;
    return this.http.get<OrderDto>(url);
  }

  public sendViaEmail(number: string, mail: string): Observable<any> {
    const url = `${this.url}/pdf/${number}?mail=${mail}`;
    return this.http.get<any>(url);
  }
  public markAsPaid(orderId: number): Observable<OrderDto> {
    const url = `${this.url}/${orderId}/paid`;
    return this.http.patch<OrderDto>(url, null);
  }
  public markAsCanceled(orderId: number): Observable<OrderDto> {
    const url = `${this.url}/${orderId}/canceled`;
    return this.http.patch<OrderDto>(url, null);
  }
  public deleteOrder(orderId: number): Observable<OrderDto> {
    const url = `${this.url}/${orderId}`;
    return this.http.delete<OrderDto>(url);
  }
  public paymentOrder(id: number): Observable<OrderDto> {
    const url = `${this.url}/${id}/pay`;
    return this.http.post<OrderDto>(url, null);
  }
  public exportProductLicenseInOrder(itemId: number, ): Observable<Blob> {
    const url = `${this.url}/item/${itemId}/licenses/export`;
    return this.http.get(url, { responseType: 'blob' });
  }
  public getMyFactures(page: number, size: number) {
    const url = `${this.url}/factures`;
    const params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());
    return this.http.get<PageableDTO<OrderDto>>(url, { params });
  }
  public getFactures(page: number, size: number , query?: string) {
    const url = `${this.url}/invoice/pages`;
    let params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());
    if (query){
      params = params.append('query', query);
    }
    return this.http.get<PageableDTO<OrderDto>>(url, { params });
  }
  public downloadFacture(number: string): Observable<Blob> {
    const url = `${this.url}/download/invoice?number=${encodeURIComponent(number)}`;
    return this.http.get(url, { responseType: 'blob'});
  }
  public downloadOrder(number: string): Observable<Blob> {
    const url = `${this.url}/download/order/${number}`;
    return this.http.get(url, { responseType: 'blob'});
  }
  public updateViewStatus(orderItem: OrderItemDto): Observable<void> {
    const url = `${this.url}/ViewKey`;
    return this.http.patch<void>(url, orderItem);
  }
  public getOrdersByStatus(status: string, page: number, size: number): Observable<PageableDTO<OrderOptDto>>{
    const url = `${this.url}/status`;
    const params = new HttpParams()
      .append('status', status)
      .append('page', page.toString())
      .append('size', size.toString());
    return this.http.get<PageableDTO<OrderOptDto>>(url, { params });
  }
  public downloadDeliveryNote(number: string): Observable<Blob> {
    const url = `${this.url}/download/delivery`;
    const params = new HttpParams()
      .append('number', number);
    return this.http.get(url, {params, responseType: 'blob'});
  }

  public getOrdersByFiltresOpt(
    status: string,
    page: number,
    size: number,
    startingDate: string,
    endingDate: string,
    search: string ): Observable<PageableDTO<OrderOptDto>>{
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());
    params = params.append(
      'search',
      search ? search : ''
    );
    params = status
      ? params.append('status', status)
      : params;
    params = startingDate
      ? params.append('startingDate', startingDate)
      : params;
    params = endingDate
      ? params.append('endingDate', endingDate)
      : params;
    const options = { params };
    const url = `${this.url}`;
    return this.http.get<PageableDTO<OrderOptDto>>(url, options); }

  public getLicensById(id: number): Observable<LicensKey[]> {
    const url = `${this.url}/OrderItem/${id}`;
    return this.http.get<LicensKey[]>(url);
  }

  public getStatusSeen(id: number): Observable<any> {
    const url = `${this.url}/OrderItem/${id}/check`;
    return this.http.get<StatusSeenDto>(url);
  }

  public getInvoice(id: number): Observable<any[]> {
    const url = `${this.url}/invoice/${id}`;
    return this.http.get<any[]>(url);
  }
  public getDelivery(id: number): Observable<any[]> {
    const url = `${this.url}/delivery/${id}`;
    return this.http.get<any[]>(url);
  }
}
