<div class="main-content" *ngIf="!loading">
  <h2 class="support-dialog-title" fxLayout="" fxLayoutAlign="center center">
    <span fxFlex=""></span>
    <span class="shine">SERVICE COMMERCIAL</span>
    <span fxFlex=""></span>
    <button  style="  background-color: var(--red-700);border:none" (click)="closeDialog()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="prefix__add-24px"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          id="prefix__Path_443"
          fill="#fff"
          d="M18 13h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 0 1 0-2h5V6a1 1 0 0 1 2 0v5h5a1 1 0 0 1 0 2z"
          data-name="Path 443"
          transform="rotate(45 12 12)"
        />
      </svg>
    </button>
  </h2>

  <div class="support-dialog-content">
    <div>
      <p *ngIf="name && lastname">
        Votre commercial: <strong>{{name}}</strong>&nbsp;<strong id="lastname"
      >{{lastname}}</strong
      >
      </p>
    <dl *ngIf="tel" fxLayout fxLayoutGap="20px">
      <dt><img src="assets/icons/phone.svg" alt=""/></dt>
      <dt>{{tel}}</dt>
    </dl>
    <dl *ngIf="mail" fxLayout fxLayoutGap="20px">
      <dt><img src="assets/icons/email.svg" alt=""/></dt>
      <dt>{{mail}}</dt>
    </dl>
    <dl fxLayout fxLayoutGap="20px">
      <dt><img src="assets/icons/time-24px.svg" alt=""/></dt>
      <dt>{{dateOuvert  }} – {{dateClose }}</dt>
      <dt [ngClass]="{ open: isOpen, closed: !isOpen }">
        {{ isOpen ? "OUVERT" : "FERMÉ" }}
      </dt>
    </dl>
    <dl fxLayout fxLayoutGap="20px" style="width: 100% !important">
      <dt><img src="assets/icons/location-24px.svg" style="width: 24px" alt=""/></dt>
      <dt>
        <a href="https://goo.gl/maps/38bZiaHYs1ZD1uNv9" target="_blank"
          >{{contactInformation[0].addressOne}}</a>
      </dt>
    </dl>
    </div>
    <div style="margin-left: -1em;width: 108%;">
      <div style="width: 100%" *ngIf="iFrame" [innerHtml]="iFrame"></div>
    <div class="support-dialog-actions" fxLayout="row" fxLayoutGap="8px">
      <button mat-button class="app-button" fxFlex="50"   (click)="sendEmail()">
        ENVOYER UN MAIL
      </button>

      <button mat-button class="app-raised-button" fxFlex="50" (click)="call()">
        Appelez-nous
      </button>
    </div>
  </div>
</div>
</div>
