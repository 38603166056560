<div fxLayout="row nowrap" fxLayoutGap="8px" fxLayoutAlign="center center">
  <button fxLayoutAlign="flex-start flex-start" mat-button class="account-btn" id="login-button"
    [class.account-btn-down]="!isConnected" [matMenuTriggerFor]="logoutmenu" #logoutMenuTrigger="matMenuTrigger"
    cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin" (click)="onAccountClick()">
    <div [ngClass]="{ 'no-company-info': !companyName || !companyNumber }"><img fxFlex="24px" src="assets/icons/person.svg" alt="account" />
    <span >{{ username | async }}</span></div>
    <img *ngIf="isConnected" fxFlex="10px" class="arrow" src="assets/icons/arrow-dropdown.svg" alt="">
    <span
      fxFlex="auto"
      *ngIf="isConnected"
      class="companyName"
      >{{companyName}}
    </span>
    <span
      fxFlex="auto"
      *ngIf="isConnected"
      class="companyNumber"
    >{{companyNumber}}
    </span>
  </button>

  <!--  -->
  <mat-menu #logoutmenu="matMenu" >
    <app-logout-drop-down-menu></app-logout-drop-down-menu>
  </mat-menu>
  <!---->



  <button
    mat-icon-button
    class="favorites-btn"
    style="background-color: transparent"
   [routerLink]="!!(totalDevisItemsCount)? ['/checkout'] : null"
    [disabled]="!!!(totalDevisItemsCount)"
  >
    <img
      [src]="
        !!(totalDevisItemsCount)
          ? 'assets/icons/file_copy.svg'
          : 'assets/icons/Quotation_icon_offline.svg'
      "
      alt="favorites"
    />
  </button>

  <button
    mat-icon-button
    class="cart-btn"
    style="background-color: transparent"
    [routerLink]="!!(totalCartItemsCount | async)? ['/checkout'] : null"
    [disabled]="!!!(totalCartItemsCount | async)"
    [routerLink]="!!(totalCartItemsCount | async) ? ['/checkout'] : null"
    [matBadge]="(totalCartItemsCount | async)"
    [matBadgeHidden]="(totalCartItemsCount | async ) === 0  "
  >
    <img style="cursor: pointer"
         (click)="goToCartCheckout()"
         (keydown.enter)="goToCartCheckout()"
         [src]="!!(totalCartItemsCount | async)
          ? 'assets/icons/shopping_cart-red.svg'
          : 'assets/icons/shopping_cart.svg'

      "
         alt="cart"
    />
  </button>
  <span class="price" *ngIf="total | async" fxHide.lt-md
    >{{ total | async | number: "1.2-2" }} €</span
  >
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="overlayOpened"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="closeOverlay()"
  (detach)="closeOverlay()"
>
  <ng-container [ngSwitch]="openedPane">
    <app-login
      *ngSwitchCase="'login'"
      (goToRequestPasswordReset)="openedPane = 'request-password-reset'"
      (goToSignup)="openedPane = 'signup'"
      (loginSuccess)="closeOverlay()"
    ></app-login>

    <app-signup
      *ngSwitchCase="'signup'"
      (goToLogin)="openedPane = 'login'"
      (closeOverlay)="closeOverlay()"
    ></app-signup>

    <app-request-password-reset
      *ngSwitchCase="'request-password-reset'"
      (closeOverlay)="closeOverlay()"
    ></app-request-password-reset>
  </ng-container>
</ng-template>
