import { BreakpointObserver } from '@angular/cdk/layout';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import { floatInAnimation } from 'shared/animations/float-in.animation';
import { NewQuotationDialogComponent } from 'shared/components/new-quotation-dialog/new-quotation-dialog.component';
import { SupportDialogComponent } from 'shared/components/support-dialog/support-dialog.component';
import { LocalCartService } from 'shared/services/localCart.service';
import { LocalFavoritesService } from 'shared/services/localFavorites.service';
import { OverlayService } from 'shared/services/overlay/overlay.service';
import { SidenavService } from 'shared/services/sidenav/sidenav.service';
import { SnackbarService } from 'shared/services/snackbar/snackbar.service';
import { selectIsConnected } from 'store/account/account.selectors';
import { addToCart } from 'store/cart/cart.actions';
import { selectAddCartStatus, selectCart } from 'store/cart/cart.selectors';
import { addToFavorites } from 'store/favorites/favorites.actions';
import { ProductDTO } from 'tekkeys-common';

import { AddToCartDialogComponent } from '../../../catalog/components/add-to-cart-dialog/add-to-cart-dialog.component';
import { CartItemDTO } from '../../../../../projects/tekkeys-common/src/lib/DTO/cart-item.dto';
import {TranslateService} from '@ngx-translate/core';
import {addToQuotationItem} from "store/quotation/quotation.actions";
import {AddToDevisPopupComponent} from "../../../catalog/components/add-to-devis-popup/add-to-devis-popup.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LocalQuotationService} from "shared/services/localQuotations.service";
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-fixed-header',
  templateUrl: './fixed-header.component.html',
  styleUrls: ['./fixed-header.component.scss'],
  animations: [floatInAnimation],
})
export class FixedHeaderComponent implements OnInit, OnDestroy {
  @Input() showHomeFixedHeader = false;
  @Input() showHomeFixedHeaderMenu = false;
  @Input() showProductDetailFixedHeader = false;
  @Input() product: ProductDTO;
  @Input() showFullProductDetailFixedHeader = false;
  @Input() socket: WebSocket;
  @Input() quantityFromProduct: number = 0;

  quantity: UntypedFormControl = new UntypedFormControl(1);

  myBooleanSubject$ = new BehaviorSubject<boolean>(false);
  myBoolean$ = this.myBooleanSubject$.asObservable();


  mobile = false; // mobile mode
  addToCartLoading = new Subject<boolean>();

  loadingAddFavorite = false;
  loadingAddCart = false;
  firstState = null;

  isConnected: boolean;
  subscriptions: Subscription[] = [];
  showNavCrl: boolean;
  cartItems: Observable<CartItemDTO[]> = this.store.select(selectCart);
  quantityInCart = 0;
  oldScrollPosition = 0;

  languages: Language[] = [{key: 'fr', label: 'FR'}, {key: 'en', label: 'EN'}];

  constructor(
    public breakpointObserver: BreakpointObserver,
    private sidenavService: SidenavService,
    private overlayService: OverlayService,
    private router: Router,
    private localQuotationService: LocalQuotationService,
    private store: Store,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private localCartService: LocalCartService,
    private localFavoritesService: LocalFavoritesService,
    public readonly translateService: TranslateService
  ) {
    const browserLang = translateService.getBrowserLang();
    const match = /en|fr/.exec(browserLang);
    translateService.use(match ? browserLang : 'en');
  }

  ngOnInit(): void {
    this.breakpointObserver.observe('(max-width: 768px)').subscribe((e) => {
      this.mobile = e.matches;
    });

    this.store
      .select(selectIsConnected)
      .subscribe((e) => {
        this.isConnected = e;
        this.myBooleanSubject$.next(e);
      });

    this.cartItems.forEach((c) => {
      c.forEach((cart) => {
        if (this.product && cart.product.id === this.product.id) {
          this.quantityInCart = cart?.quantity;
        }
      });
    });
  }

  openSidenav() {
    this.sidenavService.openSidenav();
  }
  @HostListener('window:scroll', [])
  onScrollEvent() {

    const scrollTop =
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.showNavCrl = scrollTop >= 200;

    if (scrollTop >= 200) {
      this.showHomeFixedHeader = true;
    } else {
      this.showHomeFixedHeader = false;
    }

    if(scrollTop < this.oldScrollPosition) {
      this.showHomeFixedHeaderMenu = true;
    } else {
      this.showHomeFixedHeaderMenu = false;
    }
  }

  addToQuotation() {
    if (this.isConnected) {
      const products = [];
      products.push({
        product: this.product,
        quantity: 1,
        price: this.product.price,
      });
      const data_ = {
        products,
        type: 'devis',
      };
      this.dialog.open(NewQuotationDialogComponent, {
        data: data_,
        maxWidth: '100%',
      });
    } else {
      this.router.navigate(['account', 'login'], {
        queryParams: { redirectUrl: this.router.url },
      });
    }
  }
  addQuote() {
    if (!this.isConnected) {
      this.localQuotationService.addQuotationItem(
        this.product,
        this.quantity.value
      );
      this.showDialogBox(0);
      return;
    }
      const selectAddCartStateSub = this.store
        .select(selectAddCartStatus)
        .subscribe((state) => {
          if(!state.isLoading && !state.error){
            this.store.dispatch(
              addToQuotationItem({
                productId: this.product.id,
                quantity: this.quantity.value,
                price: this.product.price,
              })
            );
            this.showDialogueDevis(state.lastCartItemId);
            setTimeout(() => selectAddCartStateSub.unsubscribe(), 500);
          }
        })
  }
  showDialogueDevis(cartItemId:number){
    const data = {
      product: this.product,
      quantity: this.quantityFromProduct,
      cartQuantity: this.quantityInCart,
      //overQuantity: this.overQuantity,
      //devisQuantity: this.devisQuanitity,
      localCartQuantity: this.quantityInCart,
      remainder: this.product.remainder,
      cartItemId,
    };
    this.dialog.open(AddToDevisPopupComponent, {
      data,
      maxWidth: '100%',
    });
  }
  openSnackBar() {
    this.snackBar.open('En tant que visiteur, vous ne pouvez pas effectuer d\'achats. Veuillez contacter le gestionnaire de votre entreprise', 'FERMER', {
      duration: 3000,
      panelClass: ['mat-toolbar', 'mat-warn']
    });
  }
  addToFavorites() {
    if (this.isConnected) {
      this.store.dispatch(
        addToFavorites({
          productId: this.product.id,
        })
      );
    } else {
      this.localFavoritesService.addFavorite(this.product);
    }
  }

  addToCart() {
    if (this.isConnected) {
      this.store.dispatch(
        addToCart({
          productId: this.product.id,
          quantity: 1,
        })
      );

      const selectAddCartStateSub = this.store
        .select(selectAddCartStatus)
        .subscribe((state) => {
          // Store the first state, if not already stored
          if (!this.firstState) {
            this.firstState = state;
          }

          // Set loading state
          this.addToCartLoading.next(state.isLoading);

          // Function to handle the final check for lastCartItemId
          const handleCartState = () => {
            if ((this.quantity.value < this.product.quantity) && (state.lastCartItemId && !state.isLoading && !state.error)) {
              this.showDialogBox(state.lastCartItemId);
              selectAddCartStateSub.unsubscribe(); // Unsubscribe after successful handling
              return; // Exit here to prevent further execution
            } else {
              this.openSnackBarQuantite();
              return;
            }
          };

          // Check if we have lastCartItemId immediately, if so handle it and exit
          if (state.lastCartItemId && !state.error) {
            handleCartState();
            return; // Exit after handling the state
          }
        });
    } else {
        this.router.navigate(['/account/login'], {
          queryParams: { returnUrl: this.router.url },
        });
    }
  }
    openSnackBarQuantite() {
      this.snackBar.open(' Votre panier contient la quantité maximale actuellement disponible en stock', 'FERMER', {
        duration: 3000,
        panelClass: ['mat-toolbar', 'mat-warn']
      });
    }
  showDialogBox(cartItemId: number) {
    const data_ = {
      product: this.product,
      cartQuantity: this.quantityInCart,
      quantity: this.quantity.value,
      cartItemId,
    };

    this.dialog.open(AddToCartDialogComponent, {
      data: data_,
      maxWidth: '100%',
    });
  }

  openSupportDialog() {
    const supportDialog = new ComponentPortal(SupportDialogComponent);
    this.overlayService.openDialog(supportDialog);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
