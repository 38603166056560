import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductFavOptDto } from 'dist/tekkeys-common/lib/DTO/productFavOpt.dto';
import { QuotationItemOptDto } from 'projects/tekkeys-common/src/public-api';
import { BehaviorSubject } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';
import { loadQuotation } from 'store/quotation/quotation.actions';
import { selectQuotation } from 'store/quotation/quotation.selectors';
import { ProductDTO, QuotationItemDto, QuotationService } from 'tekkeys-common';

@Injectable({
  providedIn: 'root',
})
export class LocalQuotationService {
  quotationItems: QuotationItemDto[] = [];
  quotationItemsOpt: QuotationItemOptDto[] = [];
  quotationSubject = new BehaviorSubject<QuotationItemDto[]>([]);
  quotationId = 0;
  public readonly quotationItems$ = this.quotationSubject.asObservable();

  constructor(private quotationService: QuotationService,private store: Store) {
    this.quotationItems = localStorage.getItem('quotation')
      ? JSON.parse(localStorage.getItem('quotation'))
      : [];
    this.quotationSubject.next(this.quotationItems);
  }

  getQuotationItems() {
    this.quotationItems = localStorage.getItem('quotation')
      ? JSON.parse(localStorage.getItem('quotation'))
      : this.quotationItems;
    this.quotationSubject.next(this.quotationItems);
    return this.quotationItems;
  }

  handleAddQuotationItem(product: ProductDTO, quantity: number, quotationId:number) {
    let quotationItem = this.quotationItems?.find(
      (q) => q.product.id === product.id
    );    
    quotationItem = quotationItem
      ? {
        ...quotationItem,
        quotationId: this.quotationId,
          quantity: quantity + quotationItem.quantity,
          itemPrice: (quantity + quotationItem.quantity) * quotationItem.price,
        }
      : {
          quotationId: this.quotationId,
          quantity,
          price: product.price,
          itemPrice: product.price,
          product,
      };
    
    this.quotationItems = [
      ...this.quotationItems?.filter((c) => c.product.id !== product.id),
      quotationItem,
    ];

     localStorage.setItem("quotation", JSON.stringify(this.quotationItems));
    //TODO :move this to a separate function
    for (let i = 0; i < this.quotationItems?.length; i++) {
    const item = this.quotationItems[i];
    
      if (item.quotationId && item.quotationId !== 0) {
        this.quotationId = quotationId;
        this.synchQuotationItemsId({ id: item.quotationId });
        break;  // Exit the loop early
      } else {
        this.synchQuotationItemsId({ id: 0 });
      }
      
    }
    this.getQuotationItems();
  }
  addQuotationItem(product: ProductDTO, quantity: number) {

    this.quotationService.getCurrent().subscribe(value => {
      if (value?.id) {
        this.quotationId = value.id; 
      }
    });
    this.handleAddQuotationItem(product, quantity, this.quotationId)
    
  }

  addQuotationItemOpt(product: ProductFavOptDto, quantity: number) {
    let quotationItem = this.quotationItemsOpt.find(
      (q) => q.product.id === product.id
    );
    quotationItem = quotationItem
      ? {
          ...quotationItem,
          quantity: quantity + quotationItem.quantity,
          itemPrice: (quantity + quotationItem.quantity) * quotationItem.price,
        }
      : {
          quotationId: 0,
          quantity,
          price: product.price,
          itemPrice: product.price,
          product,
        };
    this.quotationItemsOpt = [
      ...this.quotationItemsOpt.filter((c) => c.product.id !== product.id),
      quotationItem,
    ];
    localStorage.setItem('quotation', JSON.stringify(this.quotationItems));
    this.getQuotationItems();
  }

  updateQuotationItem(quotationItem: QuotationItemDto) {
    localStorage.setItem(
      'quotation',
      JSON.stringify([
        ...this.quotationItems.filter(
          (c) => c.product.id !== quotationItem.product.id
        ),
        quotationItem,
      ])
    );
    this.getQuotationItems();
  }

  // Gets correct id from store 
  getQuotationItemId(localItem: QuotationItemDto): any {
    let quotationId: any;
  
    this.store.dispatch(loadQuotation());
  
    this.store.select(selectQuotation).pipe(take(1)).subscribe((itemsFromStore) => {
      const matchingItem = itemsFromStore.find(
        (storeItem) => storeItem.product.id === localItem.product.id
      );
  
      if (matchingItem) {
        quotationId = matchingItem.id;
      }
    });
  
    return quotationId;
  }
  
  deleteQuotationItem(productId: number) {
    localStorage.setItem(
      'quotation',
      JSON.stringify(
        this.quotationItems.filter((f) => f.product.id !== productId)
      )
    );
    this.getQuotationItems();
  }

  addToQuotations() {
    if (this.getQuotationItems().length) {
      this.quotationService
        .addItems(this.getQuotationItems())
        .pipe(take(1))
        .subscribe();
      localStorage.setItem('route', '"/checkout"');
    }
    this.clearQuotations();
  }

  synchQuotationItemsId(data:any) {
    if (data?.id) {
      this.quotationId = data.id;
      this.quotationItems.forEach((e) => {e.quotationId = data.id});
      localStorage.setItem("quotation", JSON.stringify(this.quotationItems));
      this.quotationSubject.next(this.quotationItems);
    }
  }

  clearQuotations() {
    if (JSON.parse(localStorage.getItem('quotation'))) {
      localStorage.removeItem('quotation');
      this.quotationItems = [];
      this.quotationSubject.next(null);
      this.getQuotationItems();
    }
  }
}
