<div class="divv">
  <button mat-icon-button class="button-close" (click)="closeDialog()">
    <img src="assets/icons/close.svg" alt="close" />
  </button>
  <img src="assets/icons/mail-img.svg" alt="">
  <span class="first-part">Devis envoyé</span>


  <span class="second-part">Devis N°: <strong>{{data.quotationNum}}</strong><br/>
    Félicitation, votre devis à bien était envoyé.
    Nous vous avons envoyé un <br> e-mail de confirmation sur <strong>{{data.email}}</strong><br>
    Nous allons prendre en charge votre devis,
    vous recevrez un autre e-mail une fois votre devis traité.<br>
    <span>Vous pouvez aussi nous contacter en cliquant sur le bouton "Appelez".
    </span>L'équipe Imtech vous remercie.
</span>

  <div class="delivery">
    <img matPrefix src="assets/icons/livraison-gratuite.svg" alt="">
    <span class="third-part">Votre colis sera livré entre<strong> 4 et 7  <br/> jours ouvrables  </strong> </span>
  </div>

  <div>
    <div class="two-buttons">
      <button style="cursor: pointer"(click)="closeDialog()" [routerLink]="'/' " >
        Accueil
      </button>

      <button mat-button (click)="closeDialog(); redirect()">
        Détail
      </button>
    </div>

    <button mat-button class="call-button">
      <img alt="" src="assets/icons/phone-white.svg">
      <a href="tel:+33180899734">
        Appelez  +33 5 61 40 01 73
      </a>
    </button>
  </div>
</div>



